import NextI18Next from 'next-i18next';

const DEFAULT_LANGUAGE = 'pl';
const OTHER_LANGUAGES = ['pl', 'en', 'de'];

const sharedOptions = {
  defaultLanguage: DEFAULT_LANGUAGE,
  otherLanguages: OTHER_LANGUAGES,
  localeSubpaths: {
    pl: 'pl',
    de: 'de',
    en: 'en',
  },
  ignoreRoutes: ['/_next/', '/static/', '/public/', '/api/'],
  debug: process.env.NODE_ENV !== 'production',
};

let processOptions;

if (!process.browser) {
  processOptions = {
    lng: DEFAULT_LANGUAGE,
    preload: OTHER_LANGUAGES, // preload all langages
  };
} else {
  processOptions = {
    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
      format: (value, format) => {
        if (format === 'uppercase') return value.toUpperCase();
        return value;
      },
    },
  };
}

const options = {
  ...sharedOptions,
  ...processOptions,
};

const NextI18NextInstance = new NextI18Next(options);

export default NextI18NextInstance;

export const { i18n, Link, Trans, appWithTranslation, useTranslation, withTranslation, Router } = NextI18NextInstance;
